function isNavgationKeyCode(keyCode) {
  switch (keyCode) {
    case 37: // left
    case 38: // up
    case 39: // right
    case 40: // down
      return true
    default:
      return false
  }
}

function getTargetCell(target) {
  return $(target).closest('td, th')
}

function getNextCell(row, cellIndex, direction) {
  let index = cellIndex

  switch(direction) {
    case 'left':
      index = cellIndex - 1
      break
    case 'right':
      index = cellIndex + 1
      break
  }

  const cell = row.find('td, th').eq(index)
  return cell.length > 0 ? cell : null
}

function getFocusableElement(cell) {
  let target = null
  const _switch = cell.find('.switch')
  
  if (_switch.length > 0) {
    target = _switch.find('label')
  } else {
    target = cell.find('input, select, textarea, .dropdown-toggle, .trafficlight-toggle')
  }

  if (target.attr('disabled')) {
    target = null
  }

  return target
}

function selectTarget(target) {
  if (!target) {
    return
  }

  if (target.length === 0) {
    return
  }

  const elem = target[0]
  if (typeof elem.select === 'function') {
    elem.select()
  } else {
    elem.focus()
  }
}

function getTargetInput(row, cell, direction) {
  const cells = row.find('th, td')
  let cellIndex = cell.index()
  let target = null
  let rows = null
  let rowIndex = null

  if (direction === 'up' || direction === 'down') {
    rows = row.closest('tbody').find('tr')
    rowIndex = row.index()
  }


  // Handle cells with more than one input differently when navigation left or right
  if (direction === 'left' || direction === 'right') {
    let inputs = cell.find('input')
    if (inputs.length > 1) {
      let currentInputIndex = $(document.activeElement).index()
      
      if (currentInputIndex === inputs.length) {
        currentInputIndex = currentInputIndex - 1
      }

      if (direction === 'left' && currentInputIndex > 0) {
        inputs[currentInputIndex - 1].select()
        return
      }

      if (direction === 'right' && currentInputIndex < inputs.length - 1) {
        inputs[currentInputIndex + 1].select()
        return
      }
    
    }
  }
  
  if (direction === 'up') {

    for (let i = rowIndex; i >= 0; i--) {
      let currentRow = $(rows[i])
      let currentCell = $(currentRow.find('th, td')[cellIndex])
      target = getFocusableElement(currentCell)
      if (target && target.length > 0) {
        break
      }
    }

  } else if (direction === 'down') {

    for (let i = rowIndex; i < rows.length; i++) {
      let currentRow = $(rows[i])
      let currentCell = $(currentRow.find('th, td')[cellIndex])
      target = getFocusableElement(currentCell)
      if (target && target.length > 0) {
        break
      }
    }

  } else if (direction === 'right') {
    for (let i = cellIndex + 1; i < cells.length; i++) {
      target = getFocusableElement($(cells[i]))
      if (target && target.length > 0) {
        break
      }
    }
  } else if (direction === 'left') {
    for (let i = cellIndex - 1; i >= 0; i--) {
      target = getFocusableElement($(cells[i]))
      if (target && target.length > 0) {
        break
      }
    }
  }
  
  selectTarget(target)
} 

function navigateLeft(cell) {
  if (cell.length < 1) {
    return
  }
  
  const cellIndex = cell.index()
  if (cellIndex === 0) {
    return
  }

  const row = cell.closest('tr')
  getTargetInput(row, cell, 'left')
}

function navigateUp(cell) {
  if (cell.length < 1) {
    return
  }

  const row = cell.closest('tr').prev('tr')
  if (row.length === 0) {
    return
  }

  getTargetInput(row, cell, 'up')
}

function navigateRight(cell) {
  const row = cell.closest('tr')
  const cells = row.find('th, td')

  if (cell.index() >= cells.length - 1) {
    return
  }

  getTargetInput(row, cell, 'right')
}

function navigateDown(cell) {
  if (cell.length < 1) {
    return
  }

  const row = cell.closest('tr').next('tr')
  if (row.length === 0) {
    return
  }

  getTargetInput(row, cell, 'down')
}


$(document).on('keydown', 'table.is-navigatable tbody', (e) => {
  if (!isNavgationKeyCode(e.keyCode)) {
    return
  }

  // NOTE: If focus is in a text input allow the user to use arrow keys to step between characters unilt at beginning or end before navigation to other field.
  // TODO: Find a way to handle inputs of type number
  if (e.target.tagName === 'INPUT') {

    if (!$(e.target).hasClass('is-date') && !$(e.target).hasClass('datetime-date')) {
      
      switch (e.target.type) {
        case 'month':
        case 'search':
        case 'tel':
        case 'text':
        case 'password':
        case 'url':
        case 'week':
          if (e.keyCode === 37 && e.target.selectionStart !== 0) {
            return
          }

          if (e.keyCode === 39 && e.target.selectionStart < e.target.value.length) {
            return
          }
      }

    }
  }

  e.preventDefault()
  e.stopPropagation()

  const cell = getTargetCell(e.target)

  switch (e.keyCode) {
    case 37: // left
      navigateLeft(cell)
      break
    case 38: // up
      navigateUp(cell)
      break
    case 39: // right
      navigateRight(cell)
      break
    case 40: // Arrow down
      navigateDown(cell)
      break
  }
})