let oldPosition = 0
let scrollTimeout = null

function onScroll(scrollPos) {
  let hideHeader = false

  // Scrolling down
  if (oldPosition < scrollPos) {
    if (scrollPos > 60) {
      hideHeader = true
    }
  }

  $('.siteheader').toggleClass('is-out_of_view', hideHeader)
  $('.usernav').toggleClass('is-out_of_view', hideHeader)

  oldPosition = scrollPos
}

$(window).on('scroll', () => {
  if (scrollTimeout) {
    clearTimeout(scrollTimeout)
  }

  scrollTimeout = setTimeout(() => {
    onScroll(window.scrollY)
  }, 100)
})