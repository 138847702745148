$(document).on('click', '.tabs.is-enabled .tabs-nav-item', function(e) {
  e.preventDefault()
  console.log($(this).attr('data-id'))
  const toggle = $(this)
  const module = toggle.closest('.tabs')
  module.find('.tabs-nav-item').removeClass('is-active')
  module.find('.tabs-content').removeClass('is-active')


  toggle.addClass('is-active')
  $($(this).attr('data-id')).addClass('is-active')
});