
function setTabIndex(nav) {
  nav.find('a').attr('tabindex', 0)
}

function unsetTabIndex(nav) {
  nav.find('a').attr('tabindex', -1)
}

function close() {
  var nav = $('.navigation')
  nav.removeClass('is-active')
  $('html').removeClass('modal-mode')
  unsetTabIndex(nav)
  $('.navigation-toggle')[0].focus()
  $('body').off('keydown.navigation')
}

function open() {
  var nav = $('.navigation')
  nav.toggleClass('is-active')
  $('html').toggleClass('modal-mode', nav.hasClass('is-active'))

  setTabIndex(nav)
  nav.find('.navigation-close')[0].focus()

  $('body').on('keydown.navigation', function(e) {
    if (e.keyCode === 27          ) {
      close()
    }
  })
}

function setSelectedNavItem() {
  let id = null

  $('.navigation .navigation-item.is-current').removeClass('is-current')

  if (!window.location.hash) {
    // console.log('No hash')
    id = window.location.pathname

  } else {
    if (window.location.hash === '#/' || window.location.hash === '#') {
      id = 'start'
    } else {
      id = window.location.hash.replace('#/', '')
      
      // Removes querytrings in hash
      if (id.indexOf('?') > -1) {
        id = id.substr(0, id.indexOf('?'))
      }
    }
  }

  if (id) {
    if (id[0] === '/') {
      id = id.substr(1, id.length)
    }
    id = id.replace(/\//g, '_')
    $(`#${id}`).addClass('is-current')
  }
}

$(function() {
  var nav = $('.navigation')
  unsetTabIndex(nav)
})

function getKeyboardFocusableElements (element = document) {
  return [...element.querySelectorAll(
    'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
  )]
    .filter((el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'))
}

$(document).on('click', '.navigation-toggle', open)
$(document).on('click', '.navigation-backdrop, .navigation-close, .navigation-item', close)
$(window).on('hashchange', setSelectedNavItem)

$(document).on('keydown', '.siteheader-logo', function(e) {
  if (e.keyCode === 9 && !e.shiftKey) {
    let userNav = $('.usernav-toggle')
    if (userNav.length > 0) {
      e.preventDefault()
      userNav[0].focus()
    }
  }
})

$(document).on('keydown', '.usernav-toggle', function(e) {
  if (e.keyCode === 9) {
    if (e.shiftKey) {
      let logo = $('.siteheader-logo')
      if (logo.length > 0) {
        e.preventDefault()
        logo[0].focus()
      }
    } else {


      var focusableElements = getKeyboardFocusableElements(document.querySelector('.wrapper'))
      if (focusableElements.length > 0) {
        focusableElements[0].focus()
      }
    }
  }
})





setSelectedNavItem()

