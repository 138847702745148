$(document).on('click', '.accordion-item-toggle', function(e) {
  let toggle = $(this)
  let parent = toggle.closest('.accordion')
  
  if (parent.hasClass('is-component')) {
    return
  }
 
  e.preventDefault()

  toggle.closest('.accordion-item').toggleClass('is-active')

  let items = parent.find('.accordion-item')
  let closedCount = 0
  let openedCount = 0
  items.each((count, item) => {
    if ($(item).hasClass('is-active')) {
      openedCount++
    } else {
      closedCount++
    }
  })

  if (openedCount == items.length) {
    parent.find('.accordion-header-toggle.hide-all').removeClass('is-hidden')
    parent.find('.accordion-header-toggle.show-all').addClass('is-hidden')  
  } else if(closedCount == items.length) {
    parent.find('.accordion-header-toggle.hide-all').addClass('is-hidden')
    parent.find('.accordion-header-toggle.show-all').removeClass('is-hidden')  
  }
  
})

$(document).on('click', '.accordion-header-toggle.show-all', function(e) {
  let toggle = $(this)
  let parent = toggle.closest('.accordion')

  parent.find('.accordion-item').addClass('is-active')
  toggle.addClass('is-hidden')
  parent.find('.accordion-header-toggle.hide-all').removeClass('is-hidden')
  
})

$(document).on('click', '.accordion-header-toggle.hide-all', function(e) {
  let toggle = $(this)
  let parent = toggle.closest('.accordion')
  
  parent.find('.accordion-item').removeClass('is-active')
  toggle.addClass('is-hidden')
  parent.find('.accordion-header-toggle.show-all').removeClass('is-hidden')
})