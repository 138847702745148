import Cookies from 'js-cookie'

if (Cookies.get('darkMode') === 'true') {
  $('html').addClass('is-dark_theme')
} else {
  $('html').addClass('is-light_theme')
}

$(document).on('click', '.theme-toggle', () => {
  $('html')
    .toggleClass('is-dark_theme')
    .toggleClass('is-light_theme')
  
  Cookies.set('darkMode', $('html').hasClass('is-dark_theme'), {
    expires: 365, 
    path: ''
  })
})