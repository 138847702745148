// Functions
function hasLowerCase(value) {
  if (/(?=.*[a-z])/.test(value)) {
    return true
  }

  let exists = false
  const characters = ['å', 'ä', 'ö']
  characters.forEach((character) => {
    if (value.indexOf(character) > -1) {
      exists = true
    }
  })

  return exists
}

function hasUpperCase(value) {
  if (/(?=.*[A-Z])/.test(value)) {
    return true
  }

  let exists = false
  const characters = ['Å', 'Ä', 'Ö']
  characters.forEach((character) => {
    if (value.indexOf(character) > -1) {
      exists = true
    }
  })

  return exists
}

function hasSpecialCharacter(value) {
  let localValue = 
    value
    .replace(/å/g, '')
    .replace(/'å'/g, '')
    .replace(/ö/g, '')
    .replace(/Å/g, '')
    .replace(/Ä/g, '')
    .replace(/Ö/g, '')
    .replace(/ /g, '')

    return /(?=.*[\~\`\!\@\#\£\$\%\^\&\*\(\)\_\-\+\=\]\{\[\}\|\:\;\"\'\<\,\>\.\?\/])/.test(localValue)
}

function validateInput(e) {
  const value = e.target.value
  const validLength = value.length >= 8
  const validNumber = /\d/.test(value)
  const validLowerCase = hasLowerCase(value)
  const validUpperCase = hasUpperCase(value)
  const validSpecialCharacter = hasSpecialCharacter(value)

  $('.password-validation-item.is-length').toggleClass('is-valid', validLength)
  $('.password-validation-item.is-number').toggleClass('is-valid', validNumber)
  $('.password-validation-item.is-lowercase').toggleClass('is-valid', validLowerCase)
  $('.password-validation-item.is-uppercase').toggleClass('is-valid', validUpperCase)
  $('.password-validation-item.is-special_character').toggleClass('is-valid', validSpecialCharacter)

  return validLength && validNumber && validLowerCase && validUpperCase && validSpecialCharacter
}

function validatePassword(e) {
  if (validateInput(e)) {
    $('#password-submit').removeAttr('disabled')  
  } else {
    $('#password-submit').attr('disabled', true)
  }
}


// Event handlers

$(document).on('input', '.password-input', validatePassword)

$('#password-submit').attr('disabled', true)
$('.password-validation.is-hidden, .password-toggle.is-hidden').removeClass('is-hidden')

$(document).on('click', '.password-toggle', (e) => {
  e.preventDefault()
  const container = $(e.target).closest('.password')
  const input = container.find('.password-input')
  if (input.attr('type') === 'password') {
    container.addClass('has-visible_password')
    input.attr('type', 'text')
  } else {
    container.removeClass('has-visible_password')
    input.attr('type', 'password')
  }
})