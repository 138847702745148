import ActionCable from 'actioncable'

  let initialized = false

  function init() {
    const customerId = document.querySelector('[name=customer_id]') ? document.querySelector('[name=customer_id]').content : null

    if (customerId && !initialized) {
      setUpChannel(customerId)
    }
  }

  function setUpChannel(customerId) {
    const actionCable = ActionCable.createConsumer()

    const channel = actionCable.subscriptions.create({ 
      channel: `CustomerChannel`,
      customer_id: customerId
    }, 
    {
      received: (data) => {
        if (data.type === 'migration_status_changed') {
          if (data.migration_status === 'migrating') {
            window.location.href = '/migrations/migrating'
          } else if (data.migration_status === 'migrated') {
            window.location.href = '/migrations/migrated'
          } else if (data.migration_status === 'not_migrated') {
            window.location.href = '/'
          }
        }
      }
    })

    initialized = true
  }

  $(() => {
    init()
  })
    
  $(document).on('click', '#copyUrl', (e) => {
    const urlElement = document.querySelector('#newUrl')
    let text = urlElement.textContent;
    navigator.clipboard.writeText(text)
  })