$(function() {
  $('.orgchart-item.is-current')
    .parents('.orgchart-item')
    .addClass('is-open')
})

$(document).on('click', '.orgchart-item-toggle', function(e) {
  $(this).closest('.orgchart-item').toggleClass('is-open')
})

