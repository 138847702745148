
import * as FilePond from 'filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

// import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
// import FilePondPluginImageResize from 'filepond-plugin-image-resize'
// import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
// import FilePondPluginImageEdit from 'filepond-plugin-image-edit'


if (document.querySelector('.organization-form') && document.getElementById('logo')) {
  FilePond.registerPlugin(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    // FilePondPluginImageCrop,
    // FilePondPluginImageResize,
    // FilePondPluginImageTransform,
    // FilePondPluginImageEdit
  );
  
  console.log(document.querySelector('.organization-form').action)
  
  let serverConfig = {
    load: {
      url: '/file_uploads/?url=',
      method: 'GET',
    },
    process: {
      method: 'put',
      url: document.querySelector('.organization-form').action + '.json',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    },
    revert: {
      method: 'delete',
      url: document.querySelector('.organization-form').action + '/destroy_logo.json' ,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    }
  }
  
  let logo = FilePond.create(document.querySelector('.organization-logo'), {
    server: serverConfig,
  })

    
  if (document.getElementById('logo').value) {
    logo.files = [{
      source: document.getElementById('logo').value,
       // set type to local to indicate an already uploaded file
       options: {
        type: 'local'
      }
    }]
  }
  
  let avatarServerConfig = serverConfig
  
  avatarServerConfig.revert.url = document.querySelector('.organization-form').action + '/destroy_avatar.json'
  
  let avatar = FilePond.create(document.querySelector('.organization-avatar'), {
    server: avatarServerConfig,
    
    styleLoadIndicatorPosition: 'center bottom',
    styleProgressIndicatorPosition: 'center bottom',
    styleButtonRemoveItemPosition: 'center bottom',
    styleButtonProcessItemPosition: 'center bottom',
  })
  
  if (document.getElementById('avatar').value) {
    avatar.files = [{
      source: document.getElementById('avatar').value,
      // set type to local to indicate an already uploaded file
      options: {
        type: 'local'
      }
    }]
  }  
  
}
